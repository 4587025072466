import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import notifReducer from "./notif/reducer";
import articleSlice from "./slices/article";
import contactUsSlice from "./slices/contact-us";
import aboutUsSlice from "./slices/about-us";
import eventSlice from "./slices/event";
import jobOfferingSlice from "./slices/job-offering";
import partnershipSlice from "./slices/partnership";
import newsSlice from "./slices/news";
import programSlice from "./slices/program";

const store = configureStore({
  reducer: {
    notif: notifReducer,
    auth: authReducer,
    articles: articleSlice,
    aboutus: aboutUsSlice,
    contactus: contactUsSlice,
    events: eventSlice,
    joboffers: jobOfferingSlice,
    partners: partnershipSlice,
    news: newsSlice,
    programs: programSlice
  },
});

export default store;
