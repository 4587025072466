import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../utils/fetch';

export const getDataArticle = createAsyncThunk("article/getData", async (args, { rejectWithValue }) => {
    try {
        const result = await getData(`/cms/articles`);
        return result.data.data;
    } catch (error) {
        rejectWithValue(error.response.data);
    }
})

const articleSlice = createSlice({
    name: "article",
    initialState: {
        data: [],
        page: 1,
        limit: 10,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDataArticle.pending, (state, action) => {
            state.status = 'loading';
        })
            .addCase(getDataArticle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(getDataArticle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export default articleSlice.reducer;