import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../utils/fetch";

export const getDataProgram = createAsyncThunk(
  "program/getData",
  async (args, { rejectWithValue }) => {
    try {
      const result = await getData(`/cms/programs`);
      return result.data.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const programSlice = createSlice({
  name: "progam",
  initialState: {
    data: [],
    page: 1,
    limit: 10,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataProgram.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDataProgram.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getDataProgram.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default programSlice.reducer;
